import React from 'react';
import ContentPageLayout from '../components/ContentPageLayout';
import LegalDisclaimer from '../components/LegalDisclaimer';

const Page = () => (
  <ContentPageLayout title="Aviso Legal">
    <LegalDisclaimer />
  </ContentPageLayout>
);

export default Page;
