import React from 'react';
import '../ContentPageLayout/ContentPageLayout.scss';
import './LegalDisclaimer.scss';

const LegalDisclaimer = () => (
  <div className="LegalDisclaimer">
    <section className="ContentPageLayout-block">
      <p className="ContentPageLayout-text">
        En cumplimiento de lo establecido por el artículo 10 de la Ley 34/2002, de 11 de julio, de
        servicios de la sociedad de la información y de comercio electrónico, así como de la Ley
        2/2009, de 31 de marzo, por la que se regula la contratación con los consumidores de
        préstamos o créditos hipotecarios y de servicios de intermediación para la celebración de
        contratos de préstamo o crédito, a continuación se ofrece la información general del Sitio
        Web:
      </p>
      <br />
      <div className="ContentPageLayout-text">
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">Titular:</strong> NBQ Technology, S.A.U.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">Domicilio social:</strong> Calle Jordi Girona
          29, 08034
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">C.I.F.:</strong> A-65559296
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">E-mail:</strong> info@quebueno.es
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">Teléfono:</strong> +34 932 71 44 88
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">Teléfono:</strong> +34 900 83 11 63
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">Datos registrales:</strong> Registro
          Mercantil de Barcelona, Tomo 42604, Folio 99, Hoja B 411400, Inscripción 1.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">Precios de los servicios:</strong> Los
          honorarios del préstamo se componen de un interés que puede ser consultado en la página
          web cuando el cliente solicita el préstamo
        </p>
      </div>
    </section>
  </div>
);

export default LegalDisclaimer;
